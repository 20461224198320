import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { ExternalLinkIcon } from '@/atoms/Icons/ExternalLinkIcon'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { ParagraphMD, TitleXS } from '@/atoms/Text'

export interface ThumbnailProps {
  className?: string
  cloudinaryImagePath?: string
  contentAlternative?: string
  isExternalLink?: boolean
  onClick?: () => void
  posterTransformation?: string
  title?: string
}

export const TorchThumbnail: React.VFC<ThumbnailProps> = ({
  className,
  cloudinaryImagePath,
  contentAlternative,
  isExternalLink,
  onClick,
  posterTransformation = 'ar_16:9,c_thumb,q_auto,g_north',
  title,
}) => {
  const [isLoadingImage, setLoadingImage] = useState(!!cloudinaryImagePath)
  const onLoadComplete = useCallback(() => setLoadingImage(false), [])

  return (
    <div onClick={onClick} className={classNames(className)}>
      <div
        className={classNames([
          'relative w-full bg-black rounded-lg group-thumbnail overflow-hidden',
          'duration-[400ms] ease-in-out',
          { 'animate-pulse': isLoadingImage },
        ])}
        style={{ boxShadow: 'rgb(0 0 0 / 40%) 0px 24px 32px -8px, rgb(0 0 0 / 40%) 0px 12px 12px -8px' }}
      >
        {cloudinaryImagePath ? (
          <Image
            src={`/${posterTransformation}/${cloudinaryImagePath}.webp`}
            alt={title}
            width={320}
            height={180}
            layout="responsive"
            onLoadingComplete={onLoadComplete}
          />
        ) : (
          <div className="relative bg-black pt-[56.25%]">
            <ParagraphMD className="absolute left-0 top-0 flex h-full w-full items-center justify-center p-3 leading-5 sm:text-xs lg:text-lg 2xl:p-4 2xl:text-2xl 3xl:p-8 3xl:text-4xl 3xl:leading-[1.14]">
              {contentAlternative}
            </ParagraphMD>
          </div>
        )}
      </div>
      <TitleXS className="mt-3 flex flex-row items-center">
        <span className="mr-2 overflow-hidden text-ellipsis whitespace-nowrap">{title}</span>
        {isExternalLink && <ExternalLinkIcon color="white" size={16} className="shrink-0" />}
      </TitleXS>
    </div>
  )
}
