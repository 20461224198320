import React from 'react'
import { TorchesBanner } from '@/organisms/TorchesBanner'
import { GetTorchesQuery } from '@/types/codegen-federation'

export interface Props {
  torchesData: GetTorchesQuery
}

export const FeaturedContent: React.FC<Props> = ({ torchesData }) => {
  return <TorchesBanner torchesData={torchesData} />
}
