import { useEffect, useRef } from 'react'
import { GetStaticProps, NextPage } from 'next'
import { useRouter } from 'next/router'
import { paths } from '@/constants'
import { Durations } from '@/constants/durations'
import locales from '@/constants/locales'
import { RegionContextProvider } from '@/contexts/RegionContext'
import { Seo } from '@/layout/Seo'
import { getPageByPath } from '@/services/CmsService/Pages'
import { fetchPageDataContext } from '@/services/RenderService/fetchPageDataContext'
import { PageDataContext, PageModel } from '@/services/RenderService/types'
import { getTorches } from '@/services/TorchesService'
import { getGenericStaticPaths } from '@/utils/GetGenericStaticPaths'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { useSafeTrack } from '@/utils/analytics'
import { getStaticPropsErrorHandler, getUrlFromThisFilePath } from '@/utils/nextUtils/nextErrorHandlers'
import { getBasePath } from '@/utils/sitemap/base'
import { useTranslate } from '@/utils/translate/translate-client'
import { loadTranslations } from '@/utils/translate/translate-server'
import { PerfLogsProvider } from '@/utils/usePerfLogs'
import { WatchLandingView } from '@/views/WatchLandingView'
import { WatchLandingViewProps } from '@/views/WatchLandingView/WatchLandingView'

interface WatchLandingPageProps extends WatchLandingViewProps {
  locale: string
  pageDataContext: PageDataContext
  page: PageModel
}
export const WatchLandingPage: NextPage<WatchLandingPageProps> = (props) => {
  const { isReady, query } = useRouter()
  const locale = query.locale as string
  const { t } = useTranslate('common')
  const track = useSafeTrack()
  const hasHandledLoggedInQuery = useRef<boolean>(false)

  const pageDataContext = props.pageDataContext
  const page = props.page

  const basePath = getBasePath(locale)
  const url = `${basePath}${paths.watch.index}`
  const title = t('angelStudiosWatchMetaTitle', 'Watch Free Compelling Movies & TV Shows Online | Angel Studios')
  const description = t(
    'angelStudiosWatchMetaDescriptionV2',
    'Stream free movies & TV shows on Angel.com or the Angel app. Watch Angel Originals like The Wingfeather Saga, Dry Bar Comedy, and more.',
  )
  const openGraph = {
    description,
    title,
    url,
  }

  useEffect(() => {
    if (isReady && !hasHandledLoggedInQuery.current) {
      if ('loggedIn' in query) {
        hasHandledLoggedInQuery.current = true
        track('Logged In User Redirected to Watch')

        /**
         * Using history.pushState instead of next/router push avoids an additional pageView event from being fired.
         * This is widely supported in modern browsers.
         * If it's not supported, the user would maintain the query parameter which isn't a huge deal.
         * The reason for deleting the parameter is that we don't want to retrack this event
         * if the user refreshed, bookmarked or shared their link. I wanted to
         * use a hash rather than a query parameter but NextResponse.redirect does not
         * appear to send a # if supplied.
         */
        if ('pushState' in history) {
          history.pushState('', document.title, window.location.pathname)
        }
      }
    }
  }, [isReady, track, query])

  return (
    <>
      <Seo canonical={url} description={description} openGraph={openGraph} path={paths.watch.index} title={title} />
      <PerfLogsProvider>
        <RegionContextProvider>
          <WatchLandingView {...props} pageDataContext={pageDataContext} page={page} />
        </RegionContextProvider>
      </PerfLogsProvider>
    </>
  )
}

function buildCanonical(locale: string | undefined, defaultLocale: string) {
  if (locale === defaultLocale) {
    return `${paths.base}${paths.watch.index}`
  }
  return `${paths.base}/${locale}${paths.watch.index}`
}

// TODO: type for return value getStaticProps should be WatchLandingViewProps
export const getStaticProps: GetStaticProps = async ({ params, preview = true }) =>
  getStaticPropsErrorHandler({ requestUrl: getUrlFromThisFilePath(params) }, async () => {
    const locale = getLocaleFromParams(params)
    const canonical = buildCanonical(locale, locales.defaultLocale)

    const torchesData = await getTorches()

    const page = await getPageByPath(paths.watch.index, { locale, preview })
    const pageDataContext = page ? await fetchPageDataContext(page, preview, locale) : null

    return {
      props: {
        canonical,
        locale,
        preview,
        torchesData,
        pageDataContext,
        page,
        ...(await loadTranslations(locale, ['common', 'home', 'watch', 'ads', 'app-promo', 'account'])),
      },
      revalidate: Durations.FIFTEEN_MINUTES_IN_SECONDS,
    }
  })

export const getStaticPaths = getGenericStaticPaths

export default WatchLandingPage
