import { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { CircleCloseIcon } from '@/atoms/Icons/CircleCloseIcon'
import { Modal } from '@/molecules/Modal'
import { BitAngelPlayer } from '@/organisms/BitAngelPlayer'
import { PifVideoPlayerUI } from '@/organisms/BitAngelPlayer/PifVideoPlayerUI'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface VideoModalProps {
  videoUrl: string
  handleClose: () => void
  showModal: boolean
  videoClassName?: string
  videoTitle?: string
  showButton?: boolean
  autoplay?: boolean
}

export const VideoModal: React.FC<VideoModalProps> = ({
  videoUrl,
  handleClose,
  showModal,
  videoClassName,
  videoTitle,
  showButton = true,
  autoplay = false,
}) => {
  const { t } = useTranslate('watch')
  const [showPlayer, setShowPlayer] = useState(false)
  const track = useSafeTrack()

  const handleModalClose = useCallback(() => {
    handleClose()
  }, [handleClose])

  const trackVideoStart = useCallback(() => {
    track('Video Content Started', {
      title: videoTitle ?? 'Video',
      video_type: 'video',
    })
  }, [track, videoTitle])

  const trackVideoCompleted = useCallback(() => {
    track('Video Content Completed', {
      title: videoTitle ?? 'Video',
      video_type: 'video',
    })
  }, [track, videoTitle])

  useEffect(() => {
    videoUrl &&
      setTimeout(() => {
        setShowPlayer(true)
      }, 500)
  }, [videoUrl])

  return (
    <div>
      <Modal
        isOpen={showModal}
        onClose={handleModalClose}
        panelClasses="min-w-[80vw] lg:min-w-[55vw] !bg-transparent !p-0 !shadow-none"
      >
        <div className="relative overflow-hidden rounded-xl ">
          {showPlayer && (
            <BitAngelPlayer
              autoplay={autoplay}
              hideFullscreen
              className={classNames('aspect-h-9 aspect-w-16 overflow-hidden', videoClassName)}
              ui={PifVideoPlayerUI}
              url={videoUrl}
              onStart={trackVideoStart}
              onPlaybackFinished={trackVideoCompleted}
            />
          )}
          <div
            className="absolute right-2 top-2 z-[10000000] h-8 w-8 cursor-pointer rounded-full bg-transparent transition-all duration-300 ease-in-out hover:opacity-90 md:right-4 md:top-6"
            onClick={handleModalClose}
          >
            <CircleCloseIcon color="core-gray-600" size={32} className="cursor-pointer" />
          </div>
        </div>
        {showButton && (
          <div className="my-4 flex w-full items-center justify-center gap-4">
            <Button variant="oxide-bright" className="min-w-[170px] border-0 text-white" onClick={handleModalClose}>
              {t('startExploring', 'Start Exploring')}
            </Button>
          </div>
        )}
      </Modal>
    </div>
  )
}
