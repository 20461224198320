import React from 'react'
import classNames from 'classnames'
import { ResponsiveObject } from 'react-slick'
import { InternalLink } from '@/atoms/InternalLink'
import { AsH4, ParagraphMD, ParagraphXL } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { AngelFundingLink } from '@/molecules/InvestLink'
import { PlaceholderMediaCard } from '@/molecules/MediaCard'
import { HorizontalMediaScroller } from '@/organisms/HorizontalMediaScroller'
import { Torch } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { TorchThumbnail } from './TorchThumbnail'

interface Props {
  className?: string
  heading?: string
  subHeading?: string
  torchesInvesting?: (Torch | null)[] | null
  torchesExpressingInterest?: (Torch | null)[] | null
}

const responsiveCarousel: ResponsiveObject[] = [
  {
    breakpoint: 3440,
    settings: {
      slidesToScroll: 5,
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 1535,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 639,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
]

export const TorchesCarousel: React.FC<Props> = ({
  className,
  heading,
  subHeading,
  torchesExpressingInterest,
  torchesInvesting,
}) => {
  const { t } = useTranslate('watch')
  const viewAllProjects = t('viewAllProjects', 'View All Projects')

  return (
    <div className={classNames(className, 'overflow-hidden')}>
      <header>
        <ParagraphXL as={AsH4} className="mb-3 !font-semibold md:mb-4 3xl:text-2xl">
          {heading}
          {subHeading && (
            <ParagraphMD as={(p) => <sub {...p} />} color="gray-300" className="block leading-tight">
              {subHeading}
            </ParagraphMD>
          )}
        </ParagraphXL>
      </header>
      <HorizontalMediaScroller responsive={responsiveCarousel} slidesToShow={5} slidesToScroll={5}>
        {!torchesExpressingInterest &&
          !torchesInvesting &&
          Array(5)
            .fill(0)
            .map((v, i) => (
              <PlaceholderMediaCard key={i} hideTextPlaceholders className="mr-4" borderRadiusClassName="rounded-lg" />
            ))}

        {/* Projects Seeking Support */}
        {torchesInvesting?.map((torch) => {
          if (!torch?.posterCloudinaryPath || !torch?.title) return

          // TODO: This was being rendered as a div. Now it is an anchor. This seems more appropriate because it has an href. Does it work in practice?
          return (
            <AngelFundingLink
              key={torch.id}
              href={torch.investUrl || ''}
              linkContext="torch-invest-card"
              projectSlug={torch.campaignSlug as string | undefined}
            >
              <TorchThumbnail
                className="mr-4 duration-[500ms] ease-in-out hover:opacity-70"
                cloudinaryImagePath={torch.posterCloudinaryPath}
                title={torch.title}
                isExternalLink
              />
            </AngelFundingLink>
          )
        })}

        {torchesExpressingInterest?.map((torch) => {
          if (!torch?.posterCloudinaryPath || !torch?.title) return

          return (
            <InternalLink key={torch.id} href={`${paths.torches.index}/${torch.campaignSlug}`}>
              <TorchThumbnail
                className="mr-4 duration-[500ms] ease-in-out hover:opacity-70"
                cloudinaryImagePath={torch.posterCloudinaryPath}
                title={torch.title}
              />
            </InternalLink>
          )
        })}

        {(torchesInvesting?.length || torchesExpressingInterest?.length) && (
          <InternalLink href={paths.torches.index}>
            <TorchThumbnail
              className="mr-4 duration-[500ms] ease-in-out hover:opacity-70"
              contentAlternative={viewAllProjects}
            />
          </InternalLink>
        )}
      </HorizontalMediaScroller>
    </div>
  )
}
