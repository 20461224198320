import React from 'react'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsH3, ParagraphSM, TitleLG } from '@/atoms/Text'
import { BeforeYouLeaveProvider } from '@/contexts/BeforeYouLeaveContext'
import { BeforeYouLeaveModal } from '@/molecules/BeforeYouLeaveModal'
import { GetTorchesQuery } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { TorchesCarousel } from './TorchesCarousel'

export interface Props {
  torchesData: GetTorchesQuery
}

export const TorchesBanner: React.FC<Props> = ({ torchesData }) => {
  const { t } = useTranslate('watch')

  const torchesHeader = t('torchesHeader', 'Bring Good Into The World')
  const torchesSubheader = t('torchesSubheader', 'Support the next wave of uplifting content.')
  const projectsSeekingSupportHeader = t('projectsSeekingSupportHeader', 'Projects Seeking Support')

  const hasProjectsSeekingSupport = !!(
    torchesData.torchesExpressingInterest?.length || torchesData.torchesInvesting?.length
  )

  if (!hasProjectsSeekingSupport) {
    return null
  }

  return (
    <BeforeYouLeaveProvider>
      <BeforeYouLeaveModal />
      <section className="relative h-[110vw] w-full sm:h-[78vw] md:h-[49vw] lg:h-[47vw] xl:h-[46vw] 2xl:h-[37vw] xs:h-[93vw] 3xl:h-[32vw]">
        <ResponsiveBackgroundImage src="/v3/angel-studios/watch-banners/torches-blur.webp" />
        <div className="-ml-[26.5rem] md:ml-auto md:w-full 2xl:w-3/4">
          <Image
            src="/v3/angel-studios/watch-banners/torches-banner-v3"
            alt="project highlights"
            width={2533}
            height={752}
          />
        </div>
        <PaddedContainer fluid className="absolute bottom-0 w-full pb-6 sm:pb-10 3xl:pb-16">
          <TitleLG
            as={AsH3}
            className="mb-1 max-w-[7.4em] sm:max-w-none sm:text-3xl lg:text-4xl 3xl:text-6xl 3xl:font-normal"
            weight="bold"
          >
            {torchesHeader}
          </TitleLG>
          <ParagraphSM className="mb-6 sm:mb-8 sm:text-base md:mb-10 lg:mb-12 lg:text-lg 2xl:mb-14 3xl:mb-28 3xl:text-3xl">
            {torchesSubheader}
          </ParagraphSM>
          {hasProjectsSeekingSupport && (
            <TorchesCarousel
              torchesExpressingInterest={torchesData?.torchesExpressingInterest}
              torchesInvesting={torchesData?.torchesInvesting}
              heading={projectsSeekingSupportHeader}
            />
          )}
        </PaddedContainer>
      </section>
    </BeforeYouLeaveProvider>
  )
}
