import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const ExternalLinkIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'black', className = '' }) => {
  const c1 = getHexColor(color)

  return (
    <svg viewBox="0 0 24 24" focusable="false" height={size} width={size} className={className}>
      <g className="style-scope yt-icon">
        <path d="M21,21H3V3h9v1H4v16h16v-8h1V21z M15,3v1h4.32l-8.03,8.03L12,12.74l8-8V9h1V3H15z" stroke={c1} />
      </g>
    </svg>
  )
}
